const lazyLoadVideos = () => {
  window.addEventListener("load", () => {
    const lazyVideos = Array.from(document.querySelectorAll("video.js-lazy"));

    if ("IntersectionObserver" in window) {
      const lazyVideoObserver = new IntersectionObserver((entries) => {
        entries.forEach((video) => {
          if (video.isIntersecting) {
            const children = Array.from(video.target.children);

            children.forEach((videoSource) => {
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                // eslint-disable-next-line no-param-reassign
                videoSource.src = videoSource.dataset.src;
              }
            });

            video.target.load();
            video.target.classList.remove("js-lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach((lazyVideo) => {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  });
};

export default lazyLoadVideos;
