import IMask from "imask";

const FILLED_PHONE_LENGTH = 17;
const phoneMask = "+{38}(000) 000-0000";
const nameRegex = /^[A-Zабвгґджзклмнпрстфхцчшщйаеєиіїоуюяь ,.'-]+$/gi;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const errorMessageMap = {
  en: {
    name: {
      required: "Name is required",
      min: "Minimum 3 letters",
      max: "Maximum 15 letters",
      pattern: "Please, enter correct name",
    },

    email: {
      required: "Email is required",
      pattern: "Please, enter correct email",
    },

    phone: {
      required: "Phone is required",
    },
  },

  ua: {
    name: {
      required: "Необхідно вказати ім'я",
      min: "Мінімум 3 букви",
      max: "Максимум 15 літер",
      pattern: "Будь ласка, введіть правильне ім'я",
    },

    email: {
      required: "Необхідно вказати адресу електронної пошти",
      pattern: "Будь ласка, введіть правильну адресу електронної пошти",
    },

    phone: {
      required: "Телефон обов'язковий",
    },
  },

  uk: {
    name: {
      required: "Необхідно вказати ім'я",
      min: "Мінімум 3 букви",
      max: "Максимум 15 літер",
      pattern: "Будь ласка, введіть правильне ім'я",
    },

    email: {
      required: "Необхідно вказати адресу електронної пошти",
      pattern: "Будь ласка, введіть правильну адресу електронної пошти",
    },

    phone: {
      required: "Телефон обов'язковий",
    },
  },
};

const validateName = (lang, name) => {
  if (!name) return errorMessageMap[lang].name.required;

  // MAGIC! CHANGE WITH RESPONSIBILITY
  if (!(nameRegex.test(name) || nameRegex.test(name)))
    return errorMessageMap[lang].name.pattern;

  if (name.length < 3) return errorMessageMap[lang].name.min;
  if (name.length >= 15) return errorMessageMap[lang].name.max;
  return "";
};

const validateEmail = (lang, email) => {
  if (!email) return errorMessageMap[lang].email.required;
  if (!emailRegex.test(email)) return errorMessageMap[lang].email.pattern;
  return "";
};

const validatePhone = (lang, phone) => {
  if (!phone) return errorMessageMap[lang].phone.required;

  if (phone.length !== FILLED_PHONE_LENGTH)
    return errorMessageMap[lang].phone.required;

  return "";
};

const feedback = () => {
  const feedbackForm = document.querySelector(".js-contact-form");

  if (feedbackForm) {
    const { location } = document;
    const { origin } = location;
    const localization = document.querySelector("html").attributes.lang.value;
    let checkedLang;
    if (localization === "en") {
      checkedLang = "";
    } else {
      checkedLang = `${localization}/`;
    }
    const endpoint = `${origin}/${checkedLang}feedback`;

    const nameInput = feedbackForm.querySelector('input[name="name"]');
    const emailInput = feedbackForm.querySelector('input[name="email"]');

    const phoneInput = feedbackForm.querySelector('input[name="phone"]');
    IMask(phoneInput, {
      mask: phoneMask,
    });

    const allInputs = [...feedbackForm.querySelectorAll(".js-contact-input")];
    const checkbox = feedbackForm.querySelector(".js-checkbox");
    const checkboxInput = checkbox.querySelector("input");

    const setInputErrorClass = (input) =>
      input.classList.add("contact-form__input--error");
    const removeInputErrorsClass = (item) =>
      item.classList.remove("contact-form__input--error");

    const removeInputError = (element) => {
      const prevElement = element.previousSibling;
      if (!prevElement || !prevElement.classList) return;

      if ([...prevElement.classList].includes("js-contact-error")) {
        prevElement.remove();
      }
    };

    const setFormStatusLoading = () =>
      feedbackForm.classList.add("contact-form__form--loading");
    const removeFormStatusLoading = () =>
      feedbackForm.classList.remove("contact-form__form--loading");
    const setFormStatusLoaded = () =>
      feedbackForm.classList.add("contact-form__form--loaded");
    const setFormStatusFailed = () =>
      feedbackForm.classList.add("contact-form__form--failed");

    const resetAllInputs = () => {
      allInputs.forEach((input) => {
        removeInputErrorsClass(input);
        removeInputError(input);
      });
    };

    const resetAllErrors = () => {
      // eslint-disable-next-line no-unused-vars
      [...document.querySelectorAll(".js-contact-error")].forEach((input) =>
        input.remove()
      );
    };

    const resetForm = () => {
      resetAllErrors();

      setTimeout(() => {
        feedbackForm.reset();
        feedbackForm.className = "contact-form__form js-contact-form";
      }, 5000);
    };

    const createErrorElement = (content) => {
      const errorElement = document.createElement("span");
      errorElement.classList.add("contact-form__error");
      errorElement.classList.add("js-contact-error");
      errorElement.textContent = content;

      return errorElement;
    };

    const insertError = (errorMessage, referenceNode) => {
      const errorNode = createErrorElement(errorMessage);
      setInputErrorClass(referenceNode);
      referenceNode.parentNode.insertBefore(errorNode, referenceNode);
    };

    const displayValidationErrors = (errors) => {
      const { email, name, phone } = errors;

      if (email) insertError(email, emailInput);
      if (name) insertError(name, nameInput);
      if (phone) insertError(phone, phoneInput);
    };

    const validateInputs = () => {
      const nameError = validateName(localization, nameInput.value);
      const emailError = validateEmail(localization, emailInput.value);
      const phoneError = validatePhone(localization, phoneInput.value);

      return {
        isError: nameError || emailError || phoneError,
        errors: {
          name: nameError,
          email: emailError,
          phone: phoneError,
        },
      };
    };

    allInputs.forEach((input) => {
      input.addEventListener("input", () => {
        removeInputError(input);
        removeInputErrorsClass(input);
      });
    });

    checkbox.addEventListener("click", () => {
      if (checkbox.classList.contains("has-error")) {
        checkbox.classList.remove("has-error");
      }
    });

    feedbackForm.addEventListener("submit", (e) => {
      e.preventDefault();

      const { isError, errors } = validateInputs();

      if (isError) {
        resetAllInputs();
        resetAllErrors();
        displayValidationErrors(errors);
        return;
      }

      if (checkboxInput.checked) {
        resetAllInputs();
        resetAllErrors();
        setFormStatusLoading();

        fetch(endpoint, {
          method: "POST",
          body: new FormData(e.target),
        })
          .then((res) => {
            if (res.ok) {
              setFormStatusLoaded();
              resetForm();
            }

            return res.json();
          })
          .then(({ violations }) => {
            removeFormStatusLoading();

            // if (violations) {
            //   violations.forEach(({ propertyPath, _title }) => {
            //     allInputs.forEach((input) => {
            //       if (propertyPath === input.name) {
            //         setInputErrorClass(input);
            //         // createErrorItem(input, title);
            //       }
            //     });
            //   });
            // }
          })
          .catch(() => {
            setFormStatusFailed();
            resetForm();
          });
      } else {
        checkbox.classList.add("has-error");
      }
    });
  }
};

export default feedback;
