const toggleNotification = () => {
  const notification = document.querySelector('.js-notification')

  if (notification) {
    const closeNotificationButton = notification.querySelector('.js-close-btn')

    closeNotificationButton.addEventListener('click', () => {
      notification.classList.add('is-closed')
    })
  }
}

export default toggleNotification
