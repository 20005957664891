const togglePreloader = () => {
  const { body } = document
  const preloader = document.querySelector('.preloader')

  window.addEventListener('load', () => {
    requestAnimationFrame(() => {
      preloader.classList.add('preloader--hidden')
      body.classList.remove('is-overflow-hidden')
    })
  })
}

export default togglePreloader
