import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const sliderPhoto = () => {
  const swiper = new Swiper('.swiper-photo', {
    loop: true,
    speed: 500,
    effect: 'fade',
    spaceBetween: 0,
    slidesPerView: 1,
    simulateTouch: false,
    virtualTranslate: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.swiper-photo-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-photo-button-next',
      prevEl: '.swiper-photo-button-prev',
    },
  })

  const pagination = document.querySelector('.swiper-photo-pagination')

  if (pagination) {
    const paginationItems = pagination.querySelectorAll('span')

    const addingZeroToCounter = () => paginationItems.forEach(item => {
      const paginationItem = item.innerText

      // eslint-disable-next-line no-param-reassign
      item.innerText = paginationItem.length < 2 ? `0${paginationItem}` : paginationItem;
    })

    addingZeroToCounter()

    swiper.on('slideChange', addingZeroToCounter);
  }

  return swiper
}

export default sliderPhoto;
