import Swiper, {EffectFade, Navigation, Pagination} from 'swiper';

Swiper.use([Navigation, Pagination, EffectFade]);

const sliderVideo = () => {
  const swiper = new Swiper('.swiper-video', {
    loop: true,
    speed: 500,
    effect: 'fade',
    spaceBetween: 0,
    slidesPerView: 1,
    simulateTouch: false,
    virtualTranslate: true,
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.swiper-video-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-video-button-next',
      prevEl: '.swiper-video-button-prev',
    },
  })

  const paginationItems = document.querySelectorAll('.swiper-video-pagination > span')

  if (paginationItems) {
    const addingZeroToCounter = () => paginationItems.forEach(item => {
      const paginationItem = item.innerText

      // eslint-disable-next-line no-param-reassign
      item.innerText = paginationItem.length < 2 ? `0${paginationItem}` : paginationItem;
    })

    addingZeroToCounter()

    swiper.on('slideChange', addingZeroToCounter);
  }

  return swiper
}

export default sliderVideo;
