import gsap from 'gsap';
import isTouchDevice from '../helpers/detectTouch'

const menu = () => {
  const animation = gsap.timeline({paused: true})
  const { body } = document;
  const header = document.querySelector('.js-header');
  const hamburger = document.getElementById('hamburger');
  const mobileNav = document.getElementById('mobile-navigation');
  const eventType = isTouchDevice() ? 'touchstart' : 'click';

  animation.to(mobileNav, {x: '0%', opacity: 1, duration: 0.7})

  function toggleMenu() {
    header.classList.toggle('is-menu-opened');
    hamburger.classList.toggle('is-active');
    mobileNav.classList.toggle('is-opened');
    hamburger.classList.toggle('is-disabled');
    body.classList.toggle('is-overflow-hidden');
  }

  const closeMenu = () => {
    toggleMenu()
    animation.reverse();
  };

  const openMenu = () => {
    toggleMenu()
    animation.play();
  };

  hamburger.addEventListener(eventType, () => {
    const isMobileNavOpened = mobileNav.classList.contains('is-opened');

    if(isMobileNavOpened) {
      closeMenu();
    } else {
      openMenu();
    }
  });
};

export default menu;
