import menu from "./components/menu";
// import header from "./components/header";
import feedback from "./components/feedback";
import lazyLoadVideos from "./components/lazyLoadVideos";
import sliderPhoto from "./components/sliderPhoto";
import sliderVideo from "./components/sliderVideo";
import toggleNotification from "./components/toggleNotification";
import togglePreloader from "./components/togglePreloader";

lazyLoadVideos();
menu();
// header();
sliderPhoto();
sliderVideo();
feedback();
togglePreloader();
toggleNotification();
